import "./third-party-library-to-import/tom-select@2.3.1/tom-select.complete.min.js";

// Initialize necessaries behaviours
document.addEventListener("turbolinks:load", () => {
  // On change customer select on fixture form
  $("#fixture_customer_id").on("change", function () {
    var customerId = $(this).val();
    var fixtureCompetitionId = $("#fixture_competition_id");
    var fixtureRoundId = $("#fixture_round_id");
    var fixtureHomeClubId = $("#fixture_home_club_id");
    var fixtureAwayClubId = $("#fixture_away_club_id");

    if (customerId) {
      $.ajax({
        method: "PUT",
        url: "/fixtures/update_competitions_and_clubs",
        data: { customer_id: customerId },
        dataType: "json",
      }).done(function (result) {
        fixtureRoundId.find("option").remove();
        fixtureCompetitionId.find("option").remove();
        fixtureHomeClubId.find("option").remove();
        fixtureAwayClubId.find("option").remove();

        fixtureCompetitionId.append(`<option value=""></option>`);
        $.each(result.competitions, function (index, competition) {
          fixtureCompetitionId.append(
            `<option value="${competition.id}">${competition.name}</option>`
          );
        });

        fixtureHomeClubId.append(`<option value=""></option>`);
        fixtureAwayClubId.append(`<option value=""></option>`);
        $.each(result.clubs, function (index, club) {
          fixtureHomeClubId.append(
            `<option value="${club.id}">${club.name}</option>`
          );
          fixtureAwayClubId.append(
            `<option value="${club.id}">${club.name}</option>`
          );
        });

        fixtureCompetitionId.prop("disabled", false);
        fixtureHomeClubId.prop("disabled", false);
        fixtureAwayClubId.prop("disabled", false);
      });
    } else {
      fixtureRoundId.find("option").remove();
      fixtureCompetitionId.find("option").remove();
      fixtureHomeClubId.find("option").remove();
      fixtureAwayClubId.find("option").remove();

      fixtureRoundId.prop("disabled", true);
      fixtureCompetitionId.prop("disabled", true);
      fixtureHomeClubId.prop("disabled", true);
      fixtureAwayClubId.prop("disabled", true);
    }
  });

  // On change competition select on fixture form
  $("#fixture_competition_id").on("change", function () {
    var competitionId = $(this).val();
    var fixtureRoundId = $("#fixture_round_id");

    if (competitionId) {
      $.ajax({
        method: "PUT",
        url: "/fixtures/update_rounds",
        data: { competition_id: competitionId },
        dataType: "json",
      }).done(function (result) {
        fixtureRoundId.find("option").remove();

        fixtureRoundId.append(`<option value=""></option>`);
        $.each(result.rounds, function (index, round) {
          fixtureRoundId.append(
            `<option value="${round.id}">${round.name}</option>`
          );
        });

        fixtureRoundId.prop("disabled", false);
      });
    } else {
      fixtureRoundId.find("option").remove();

      fixtureRoundId.prop("disabled", true);
    }
  });

  function appointmentsSelectAllRadio(event) {
    const clickedElement = event.target;
    let value;

    if (clickedElement.classList.contains('drft')) {
        value = 'draft';
    } else if (clickedElement.classList.contains('prov')) {
        value = 'provisional';
    } else if (clickedElement.classList.contains('publ')) {
        value = 'published';
    } else if (clickedElement.classList.contains('conf')) {
      value = 'confirmed';
    } else if (clickedElement.classList.contains('decl')) {
      value = 'declined';
    }

    const tr = clickedElement.closest('table');

    if (value) {
        const radios = tr.querySelectorAll(`input[type="radio"][value="${value}"]`);

        if( radios ){
          radios.forEach((radio) => {
            radio.checked = true;
            radio.click();
          });
        }
    }
  }

  // Add click events to elements with classes representing each option
  const appointmentsSelectAllRadioTh = document.querySelectorAll('.drft, .prov, .publ, .conf, .decl');

  if( appointmentsSelectAllRadioTh && appointmentsSelectAllRadioTh.length ){
    appointmentsSelectAllRadioTh.forEach((element) => {
        element.addEventListener('click', appointmentsSelectAllRadio);
    });
  }

  setTimeout(function(){
    loadTomSelectFixturesAppointments();
    runEventsColumnColor();
  }, 100)
});


function loadTomSelectFixturesSelects( elementClass, options ){

  options = options.map(option => {
    return {
      ...option,
      id: Math.random().toString(36).substring(2, 15),
    };
  });

  document.querySelectorAll('.' + elementClass).forEach(function(element) {
    if (!element.classList.contains( elementClass + '-loaded')) {
      element.classList.remove('form-control'); 
    }
  });

  document.querySelectorAll('.' + elementClass).forEach(function(element) {
    if (!element.classList.contains( elementClass + '-loaded')) {
      element.classList.add( elementClass + '-loaded');
      if( typeof TomSelect !== 'undefined' ){
        let selectControl = new TomSelect(element, {
          options: options,  
          valueField: 'value',
          labelField: 'text',
          searchField: ['text'],
          maxItems: 1,  
          create: false,
          render: {
            option: function(data, escape) {
              let relatedElementOption = element.querySelector('option[value="' + data.value + '"]');
  
              if( data.value == "" ){
                return `<div class="ts-box-option-fixtures"><div class="ts-box-option-fixtures__texts"><div class="ts-box-option-fixtures__texts__name">${escape(data.text)}</div></div></div>`;
              }
              
              if( parseFloat(selectControl.getValue()) != data.id && relatedElementOption && relatedElementOption.hasAttribute('disabled') ){
                return `<div class="ts-box-option-fixtures" style="display: none;"></div>`;
              }
    
              var statusClass = "option-" + escape(data.status);
              var statusText = escape(data.statusText);
  
              return `<div class="ts-box-option-fixtures">
                  <div class="ts-box-option-fixtures__image">
                    <div class="ts-box-option-fixtures__image-box" style="background-image: url(${data.image_url}) "></div>
                  </div>
                  <div class="ts-box-option-fixtures__texts">
                    <div class="ts-box-option-fixtures__texts__name">${escape(data.text)}</div>
                    <div class="ts-box-option-fixtures__texts__status ${statusClass}">${statusText}</div>
                  </div>
                </div>`;
            },
            item: function(data, escape) {
              if( element.closest('tr').querySelector('.img-circle') ){
                element.closest('tr').querySelector('.img-circle').src = data.image_url;
              }
              var statusClass = "fixtures-change-official-tom-select-selected option-" + escape(data.status);
              return '<div class="' + statusClass + '">' + ( data.value == '' ? '&nbsp' : escape(data.text) ) + '</div>';
            }
          },
          onChange: function(e){
            if (!selectControl.getValue()) {
              if (options.length > 0) {
                let firstOption = options[0];
                if( element.closest('tr').querySelector('.img-circle') ){
                  element.closest('tr').querySelector('.img-circle').src = firstOption.image_url;
                }
              }
            }
          },
          onFocus: function() {
            selectControl.clearOptions();
  
            options.forEach(function(option) {
              selectControl.addOption(option);
            });
  
            selectControl.refreshOptions();
          },
          onItemAdd: function(value, item) {
            selectControl.blur();
            selectControl.close();
          }
        });
      }
    }
  });
  
}

function loadTomSelectMatchOfficialsCollection(){
  loadTomSelectFixturesSelects('fixtures-change-official-tom-select', ( typeof options_match_officials_collection !== 'undefined' ? options_match_officials_collection : false ) );
}

function loadTomSelectVideoObserverCollection(){
  loadTomSelectFixturesSelects('fixtures-change-video-observer-tom-select', ( typeof options_video_observer_collection !== 'undefined' ? options_video_observer_collection : false ));
}

function loadTomSelectVideoPanellistCollection(){
  loadTomSelectFixturesSelects('fixtures-change-video-panellist-tom-select', ( typeof options_video_panellist_collection !== 'undefined' ? options_video_panellist_collection : false ));
}

function loadTomSelectMatchOfficialsAvarCollection(){
  loadTomSelectFixturesSelects('fixtures-change-official-tom-select-avar', ( typeof options_match_officials_collection !== 'undefined' ? options_match_officials_collection : false ));
}

function loadTomSelectMatchOfficialsVarCollection(){
  loadTomSelectFixturesSelects('fixtures-change-official-tom-select-var', ( typeof options_match_officials_collection !== 'undefined' ? options_match_officials_collection : false ));
}

window.loadTomSelectFixturesAppointments = function(){
  loadTomSelectMatchOfficialsCollection();
  loadTomSelectVideoPanellistCollection();
  loadTomSelectVideoObserverCollection();
  loadTomSelectMatchOfficialsAvarCollection();
  loadTomSelectMatchOfficialsVarCollection();
}


window.runEventsColumnColor = function(){
  var elements = document.querySelectorAll('.view-fixture-table-appointments-checkbox');
    
  elements.forEach(function(element) {
    element.addEventListener('click', function() {
      var row = element.closest('tr');

      if (row) {
        ['draft', 'provisional', 'published', 'confirmed', 'declined'].forEach(function(inE){
          row.classList.remove('appointments__current-status-' + inE);
        });

        row.classList.add('appointments__current-status-' + element.value);
      }
    });
  });
}